import React from 'react';
import Header from "../header";
import Footer from "../../components/common/airFreightForwardingFooter";
import AirFreight from '../../components/common/air-freight';
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useTranslation, I18nextContext,Link } from "gatsby-plugin-react-i18next";

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL
const Product = () => {
  const { language } = React.useContext(I18nextContext)

  const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL + "/product/air-freight-software/"
      : GATSBY_LANDING_PAGE_URL + `/${language}/product/air-freight-software/`
  const canoncalUrl = buildCanonicalUrl();

  const buildHrefUrl = () =>
  language === "en"
    ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
    : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/`))
const hrefUrl = buildHrefUrl();


  const { t } = useTranslation()
  
  return (
    <div className="Layout">
      <Header />
      <AirFreight />
      <Footer />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Air Freight Forwarding Software - Best Air Cargo Software | Logipulse')}</title>  
        <Link rel="canonical" href={canoncalUrl} /> 
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
        <meta name="description" content={t("Find the No.1 Air Freight Software at the best price. With our innovative and user-friendly air cargo software, creating and managing your air shipments will be smooth and effortless. Contact now for the best air freight management software.")} /> 
        <meta name="Keywords" content={t("air freight software, air cargo software, air freight forwarding software")}/>
      </Helmet>
    </div>
  )
}
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Product